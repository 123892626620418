@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts/gravity/Gravity-Regular.otf");
}

@font-face {
  font-family: Auger-Mono-Bold;
  src: url("../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-regular.woff2");
}

:root {
  --main-text-color: rgb(26 65 119);
  --font-size: 0.875rem;
  --symposia-text-color: #0283c9;
  --font-size: 0.875rem;
  --font-small-size: 0.8rem;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
}

.conference-program-section-main-div {
  max-width: 45rem;
  margin: 0 auto;
  font-family: Gravity;
  margin-bottom: 10rem;
  color: var(--main-text-color);
}

.conference-program-section-max-header {
  font-size: 2.5rem;
  /* padding-top: 5rem; */
  margin-left: 0.5rem;
  margin-top: 8rem;
}

.conference-program-main-div-under-text {
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.conference-program-world-peter {
  margin-top: 6rem;
  opacity: 0;
  transform: translateY(100px);
}

.conference-program-world-peter-active {
  margin-top: 6rem;
  opacity: 1;
  transform: translateY(0px);
  transition: 0.5s;
}

.conference-program-world-peter-image {
  width: 100%;
  height: 100%;
}

.conference-program-download-section {
  margin-top: 0.3rem;
  color: var(--main-text-color);
}

.conference-program-download-section-title {
  font-size: 1rem;
}

.conference-program-button {
  margin-top: 0.3rem;
  font-family: Gravity;
  color: var(--var-dark-blue);
  border: none;
  padding: 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
  background-color: white;
  transition: 0.2s;
}

.conference-program-button a {
  text-decoration: none;
  color: var(--var-dark-blue);
}

.conference-program-button:hover {
  background-color: lightgray;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .conference-program-section-main-div {
      max-width: 45rem;
      margin-left: 1rem;
      margin-right: 1rem;
      font-family: Gravity;
      margin-bottom: 10rem;
      color: var(--main-text-color);
    }

    .conference-program-world-peter {
      max-width: fit-content;
      margin: 0 auto;
      /* margin-top: 6rem; */
      opacity: 0;
      transform: translateY(100px);
    }

    .conference-program-world-peter-active {
      max-width: fit-content;
      margin: 0 auto;
      /* margin-top: 6rem; */

      opacity: 1;
      transform: translateY(0px);
      transition: 0.5s;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .conference-program-section-main-div {
      max-width: 45rem;
      margin: 0 auto;
      font-family: Gravity;
      margin-bottom: 10rem;
      color: var(--main-text-color);
    }

    .conference-program-world-peter {
      /* margin-top: 6rem; */
      opacity: 0;
      transform: translateY(100px);
    }

    .conference-program-world-peter-active {
      /* margin-top: 6rem; */
      opacity: 1;
      transform: translateY(0px);
      transition: 0.5s;
    }
  }
}
