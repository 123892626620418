.carousel-main-div {
  /* background-color: #1a4177; */
  background-image: url("../Images/Carousel/PostersPresentations_Background.svg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  padding-top: 10rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.carousel-circle-small {
  width: 100px;
  height: 100px;
  background-color: #1a4177;
  border: 0.5px solid white;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-radius: 50%;
  transform: translate(-30%, 50%);
}

.carousel-circle-small-two {
  width: 150px;
  height: 150px;
  background-color: #1a4177;
  border: 0.5px solid white;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(150px, -100px);
}

.carousel-circle-small-three {
  width: 300px;
  height: 900px;
  background-color: #1a4177;
  border: 0.5px solid white;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(150px, -100px);
}

.carousel-button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  width: 3rem;
  height: 3rem;
  position: absolute;
}

.carousel-arrow-right {
  margin-top: 20px;
  right: 10px;
}

.carousel-arrow-left {
  margin-top: 20px;
  left: 10px;
}

.carousel-no-display-slide {
  display: none;
  animation: fading 10s infinite;
}

.carousel-post-presentations {
  color: white;
  font-size: 3rem;
  max-width: 30rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  display: none;
}

.carousel-post-presentations-active {
  color: white;
  font-size: 3rem;
  max-width: 30rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.carousel-slide {
  display: none;
}

.carousel-slide-active {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.carousel-arrow-right-zero {
  margin-bottom: 400px;
  right: 300px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  position: absolute;
}

.carousel-arrow-left-zero {
  display: none;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .carousel-main-text {
      font-size: 2.5rem;
      margin-left: 1rem;
    }
    .carousel-arrow-right-zero {
      margin-top: 65px;
      right: 30px;
      background: none;
      border: none;
      cursor: pointer;
      color: white;
      position: absolute;
    }

    .carousel-arrow-right {
      margin-top: 10px;
      right: 10px;
    }

    .carousel-arrow-left {
      margin-top: 10px;
      left: 10px;
    }
  }

  @media only screen and (min-width: 500px) {
    .carousel-main-text {
      font-size: 2.5rem;
      margin-left: 1rem;
    }
    .carousel-arrow-right-zero {
      margin-top: 0px;
      right: 80px;
      background: none;
      border: none;
      cursor: pointer;
      color: white;
      position: absolute;
    }

    .carousel-arrow-right {
      margin-top: 10px;
      right: 10px;
    }

    .carousel-arrow-left {
      margin-top: 10px;
      left: 10px;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 800px) {
    .carousel-arrow-right-zero {
      margin-top: 50px;
      right: 250px;
    }

    .carousel-arrow-right {
      margin-top: 20px;
      right: 10px;
    }

    .carousel-arrow-left {
      margin-top: 20px;
      left: 10px;
    }
  }
}
