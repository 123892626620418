@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts/gravity/Gravity-Regular.otf");
}

:root {
  --main-text-color: rgb(26 65 119);
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --font-size: 0.875rem;
}

.scientific-commitee-main-div {
  font-family: Gravity;
  max-width: fit-content;
  margin: 0 auto;
}

.scientific-commitee-inner-div {
  max-width: 50rem;
  margin-bottom: 7rem;
  margin-top: 7rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.scientific-commitee-title {
  color: var(--var-dark-blue);
  font-size: 2.5rem;
}

.scientific-person-title {
  color: var(--var-dark-blue);
  font-size: var(--font-size);
  font-weight: bold;
}

.scientific-person-works {
  color: var(--var-dark-blue);
  font-size: var(--font-size);
}
