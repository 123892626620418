@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts/gravity/Gravity-Regular.otf");
}

:root {
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --font-size: 0.875rem;
}

.cv-main-div {
  max-width: fit-content;
  margin: 0 auto;
  font-size: var(--font-size);
}

.cv-div-title {
  font-weight: bold;
}

.cv-div-sections {
  margin-left: 1rem;
  margin-right: 1rem;
}
