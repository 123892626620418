@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts/gravity/Gravity-Regular.otf");
}

:root {
  --main-text-color: rgb(26 65 119);
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --font-size: 0.875rem;
  --var-gray: #f7f7f7;
}

.mj10-host {
  margin-top: 1rem;
  color: var(--var-med-blue);
  font-size: var(--font-size);
  font-weight: 500;
}

.mj10-list {
  margin-top: 1rem;
  color: var(--var-med-blue);
}

.mj10-text {
  margin-top: 1rem;
  color: var(--var-dark-blue);
  font-size: var(--font-size);
  margin-left: 2rem;
  margin-right: 2rem;
}

.mj10-inner-list {
  font-size: 0.8rem;
  margin: 0;
  margin-top: 0.3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.mj10-inner-list li {
  margin-top: 0.3rem;
}
