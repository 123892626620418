@font-face {
  font-family: Auger-Mono-Light;
  src: url("../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-light.woff2");
}

@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

.committee-scientific-title {
  font-weight: 600;
  font-size: 1.4rem;
  text-align: left;
}

.committee-programme-title {
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 2rem;
  text-align: left;
}

.committee-container {
  margin: 0 auto;
  max-width: fit-content;
  margin-bottom: 1rem;
}

.committee-scientific-container {
  margin-top: 1rem;
  text-align: left;
  max-width: fit-content;
}

.committee-programme-container {
  margin-top: 1rem;
  text-align: left;
}

.committee-italic-title {
  font-style: italic;
}

.committee-outer-container {
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
  will-change: "";
}

.committee-text-left {
  opacity: 0;
  color: rgb(26 65 119);
}

.committee-text-left-active {
  opacity: 1;
  color: rgb(26 65 119);
  transition: 0.5s ease-in;
}

.committee-text-left-header {
  font-family: Gravity;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: none;
  text-decoration: none;
}

.committee-text-left-paragraph {
  font-family: Gravity;
  margin-top: 3rem;
  font-size: 1rem;
  text-transform: none;
  text-decoration: none;
  max-width: 30rem;
}

.committee-text-left-footer {
  font-family: Gravity;
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1rem;
  text-transform: none;
  text-decoration: none;
}

.comittee-grid {
  margin: 0 auto;
}

.committee-grid-right-image {
  opacity: 0;
  margin-top: 5rem;
  margin-left: 1rem;
}

.committee-grid-right-image-active {
  margin-top: 5rem;
  margin-left: 1rem;
  opacity: 1s;
  transition: 1.5s ease-in;
}

.committee-image-stairs {
  margin-top: 6rem;
  margin-left: 1rem;
  color: rgb(26 65 119);
  opacity: 0;
  transform: translateY(100px);
}

.committee-image-stairs-active {
  margin-top: 6rem;
  margin-left: 1rem;
  opacity: 1;
  transform: translateY(0px);
  transition: 0.5s;
}

.committee-west-coast-text {
  color: rgb(26 65 119);
  max-width: 30rem;
  margin-top: 10rem;
  opacity: 0;
}

.committee-west-coast-text-active {
  color: rgb(26 65 119);
  max-width: 30rem;
  margin-top: 10rem;
  opacity: 1;
  transition: 0.5s ease;
}

.committee-west-coast-text-header {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.committee-west-coast-text-paragraph {
  margin-top: 2rem;
  font-size: 1rem;
  text-transform: none;
  text-decoration: none;
}

.committee-image-bridge {
  color: rgb(26 65 119);
  margin-left: 10rem;
  margin-top: 10rem;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s;
}

.committee-image-bridge-active {
  color: rgb(26 65 119);
  margin-left: 10rem;
  margin-top: 10rem;
  transform: translateY(0px);
  opacity: 1;
  transition: 0.5s;
}

.committee-contain-div {
  max-width: fit-content;
  margin: 0 auto;
}

/* .committee-west-coast-grid-div {
  max-width: fit-content;
  margin: 0 auto;
} */

.committee-text-roll-over {
  color: transparent;
  position: absolute;
  transform: translate(5px, -25px);
  transition: 0.5s;
}

.committee-text-roll-over-active {
  color: white;
  position: absolute;
  transform: translate(5px, -25px);
  transition: 0.5s;
}

.committee-bridge-roll-over {
  color: transparent;
  position: absolute;
  transform: translate(5px, -25px);
  transition: 0.5s;
}

.committee-bridge-roll-over-active {
  color: white;
  position: absolute;
  transform: translate(5px, -25px);
  transition: 0.5s;
}

.committee-parallax-stairs {
  height: 40rem;
  width: 40%;
  /* background-position-y: -100%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../Images/alexander-serzhantov-xN0zxqQSdCk-unsplash.jpg");
  background-attachment: scroll;
}

.committee-parallax-bridge {
  height: 20rem;
  width: 50%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../Images/divit-sharma-9mvjJFDLYzg-unsplash.jpg");
}

.committee-stairs-parrallax {
  max-width: 25rem;
  height: 40rem;
  margin-top: 2rem;
  background-size: contain;
}

.committee-parallax-container-stairs {
  position: relative;
  max-width: 30rem;
  height: 600px; /* Adjust the height as needed */
  overflow: hidden;
}

.committee-parallax-image-stairs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images/alexander-serzhantov-xN0zxqQSdCk-unsplash.jpg");
  background-size: cover;
  background-position: center;
}

.parallax-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.committee-grid-column-right {
  margin-right: 1rem;
  max-width: fit-content;
}

.committee-grid-column-left {
  margin-left: 1rem;
  max-width: 40rem;
}

.committee-flex-before-961 {
  margin-right: 1rem;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .committee-grid-right-image,
    .committee-grid-right-image-active {
      margin-top: 0rem;
      margin-bottom: 3rem;
    }

    .committee-text-left,
    .committee-text-left-active {
      max-width: 30rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .committee-text-left {
      opacity: 0;
    }

    .committee-text-left-active {
      opacity: 1;
      transition: 0.5s ease-in;
    }

    .committee-image-bridge,
    .committee-image-bridge-active {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 5rem;
    }
    /* .committee-image-stairs { */
    /* margin-left: 1rem; */
    /* } */

    .committee-west-coast-text,
    .committee-west-coast-text-active {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .committee-west-coast-text {
      opacity: 0;
      transition: 0.5s ease;
    }

    .committee-west-coast-text-active {
      opacity: 1;
      transition: 0.5s ease;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .committee-grid-right-image,
    .committee-grid-right-image-active {
      margin-top: 0rem;
      margin-bottom: 3rem;
    }

    .committee-text-left,
    .committee-text-left-active {
      max-width: 30rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .committee-text-left {
      opacity: 0;
    }

    .committee-text-left-active {
      opacity: 1;
      transition: 0.5s ease-in;
    }

    .committee-image-bridge,
    .committee-image-bridge-active {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 5rem;
    }

    .committee-image-bridge {
      transform: translateY(100px);
      opacity: 0;
      transition: 1.5s;
    }

    .committee-image-bridge-active {
      transform: translateY(0px);
      opacity: 1;
      transition: 1.5s;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) {
    .committee-grid-right-image,
    .committee-grid-right-image-active {
      margin-top: 0rem;
      margin-bottom: 3rem;
    }

    .committee-text-left,
    .committee-text-left-active {
      max-width: 30rem;
    }

    .committee-text-left {
      opacity: 0;
    }

    .committee-text-left-active {
      opacity: 1;
      transition: 0.5s ease-in;
    }

    .committee-image-bridge,
    .committee-image-bridge-active {
      margin-left: 0rem;
      margin-top: 5rem;
    }

    .committee-image-bridge {
      transform: translateY(100px);
      opacity: 0;
      transition: 0.5s;
    }

    .committee-image-bridge-active {
      transform: translateY(0px);
      opacity: 1;
      transition: 0.5s;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 769px) {
    .committee-grid-right-image,
    .committee-grid-right-image-active {
      margin-top: 0rem;
      margin-bottom: 3rem;
    }

    .committee-text-left,
    .committee-text-left-active {
      max-width: 30rem;
    }

    .committee-text-left {
      opacity: 0;
    }

    .committee-text-left-active {
      opacity: 1;
      transition: 0.5s ease-in;
    }

    .committee-image-bridge,
    .committee-image-bridge-active {
      margin-left: 0rem;
      margin-top: 5rem;
    }

    .committee-image-bridge {
      transform: translateY(100px);
      opacity: 0;
      transition: 0.5s;
    }

    .committee-image-bridge-active {
      transform: translateY(0px);
      opacity: 1;
      transition: 0.5s;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .committee-grid-column-right {
      margin-right: 1rem;
      max-width: fit-content;
    }

    .committee-grid-column-left {
      margin-left: 1rem;
      max-width: 35rem;
    }

    .committee-image-bridge,
    .committee-image-bridge-active {
      margin-left: 10rem;
      margin-top: 10rem;
    }

    .committee-image-bridge {
      transform: translateY(100px);
      opacity: 0;
      transition: 0.5s;
    }

    .committee-image-bridge-active {
      transform: translateY(0px);
      opacity: 1;
      transition: 0.5s;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1124px) {
    .committee-grid-column-right {
      margin-right: 1rem;
      max-width: fit-content;
    }

    .committee-grid-column-left {
      margin-left: 1rem;
      max-width: 40rem;
    }
  }
}
