@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts/gravity/Gravity-Regular.otf");
}

:root {
  --main-text-color: rgb(26 65 119);
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --font-size: 0.875rem;
}
.components-commitee-style-text {
  margin-top: 1rem;
  font-size: var(--font-size);
  margin-bottom: 1rem;
  font-family: Gravity;
  color: var(--var-dark-blue);
  word-break: break-word;
  max-width: fit-content;
  text-transform: none;
  text-decoration: none;
  margin-left: 1rem;
  margin-right: 1rem;
}

.components-commitee-style-text-title {
  color: var(--var-dark-blue);
  font-family: Gravity;
}

.componenets-commitee-image {
  display: inline-block;
  /* -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -khtml-border-radius: 12px; */
  border: transparent;
  -webkit-transform: translateZ(0);
  overflow: hidden;
  margin-bottom: 1rem;
}

.componenets-commitee-image img {
  /* -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%; */
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -khtml-border-radius: 12px;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  overflow: hidden;
  margin-bottom: 1rem;
}

.componenets-commitee-image-middle {
  display: inline-block;
  /* -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%; */

  border: transparent;
  -webkit-transform: translateZ(0);
  margin-bottom: 1rem;
}

.componenets-commitee-image-middle img {
  /* -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%; */
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -khtml-border-radius: 12px;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  margin-bottom: 1rem;
}
