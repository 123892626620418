@font-face {
  font-family: Gravity-Light;
  src: url("../../public/Fonts/gravity/Gravity-UltraLight.otf");
}

@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts/gravity/Gravity-Regular.otf");
}

@font-face {
  font-family: Auger-Mono-Light;
  src: url("../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-light.woff2");
}
.home-grid-information {
  max-width: 55rem;
  margin-top: 0rem;
}

.home-grid-paragraph-title {
  text-align: left;
}

.home-grid-paragraph {
  text-align: left;
  margin-top: 1rem;
}

.home-div-vh-min {
  /* min-height: 100vh; */
  max-width: 60rem;
  margin: 0 auto;
}

.home-h1-div-intro {
  font-family: Gravity-Light;
  color: rgb(26 65 119);
  font-size: 100px;
  max-width: 50rem;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  margin-top: 10rem;
  font-weight: 600;
}

.home-text-under-h1 {
  font-family: Auger-Mono-Light;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
  color: rgb(0 130 200);
  max-width: fit-content;
  margin: 0 auto;
}

.home-text-under-h1-text {
  color: rgb(26 65 119);
  text-transform: uppercase;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 0.5rem;
  font-family: Gravity;
  font-weight: bold;
}

.home-text-under-ht-text-text-button {
  font-family: Gravity;
  font-size: 0.7rem;
  color: white;
  margin-top: 2.5rem;
  text-transform: uppercase;
  max-width: fit-content;
  background-color: #0482c8;
  border-radius: 20px;
  border: none;
  margin-bottom: 5rem;
  cursor: pointer;
}

.home-text-under-ht-text-text-button:hover {
  background-color: #04689e;
}

.home-arrow-scroll {
  font-family: Gravity;
  color: rgb(26 65 119);
  text-transform: uppercase;
  font-size: 0.5rem;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 5rem;
}

.home-arrow-svg {
  max-width: fit-content;
  margin: 0 auto;
}

svg {
  color: rgb(26 65 119);
}

.page-holder-aspect-ratio-frame {
  max-width: 65rem;
  margin: 0 auto;
  margin-top: 12rem;
  opacity: 0;
}

.page-holder-aspect-ratio-frame-active {
  max-width: 65rem;
  margin: 0 auto;
  margin-top: 12rem;
  opacity: 1;
  transition: 0.5s ease-in;
}

.home-main-div {
  opacity: 0;
}

.home-main-div-active {
  opacity: 1;
  transition: 3s ease-out;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .home-h1-div-intro {
      max-width: 20rem;
      font-size: 60px;
      font-family: Gravity-Light;
      font-weight: bold;
    }

    .home-text-under-h1 {
      max-width: 20rem;
      text-align: center;
      margin: 0 auto;
      font-size: 18px;
    }

    .page-holder-aspect-ratio-frame,
    .page-holder-aspect-ratio-frame-active {
      max-width: 65rem;
      margin: 0 auto;
    }

    .page-holder-aspect-ratio-frame {
      margin-top: 12rem;
      opacity: 0;
      transition: 0.5s ease-in;
    }

    .page-holder-aspect-ratio-frame-active {
      margin-top: 12rem;
      opacity: 1;
      transition: 0.5s ease-in;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .home-h1-div-intro {
      max-width: 20rem;
      font-size: 65px;
      font-family: Gravity-Light;
      font-weight: bold;
    }

    .home-text-under-h1 {
      max-width: 20rem;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
    }

    .page-holder-aspect-ratio-frame,
    .page-holder-aspect-ratio-frame-active {
      max-width: 65rem;
      margin: 0 auto;
      margin-top: 12rem;
    }

    .page-holder-aspect-ratio-frame {
      margin-top: 12rem;
      opacity: 0;
      transition: 0.5s ease-in;
    }

    .page-holder-aspect-ratio-frame-active {
      margin-top: 12rem;
      opacity: 1;
      transition: 0.5s ease-in;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .home-h1-div-intro {
      max-width: 30rem;
      font-size: 100px;
      font-family: Gravity-Light;
      font-weight: bold;
    }

    .home-text-under-h1 {
      max-width: fit-content;
      text-align: left;
      font-size: 1rem;
      margin: 0 auto;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .home-h1-div-intro {
      max-width: 50rem;
      font-size: 100px;
      font-family: Gravity-Light;
      font-weight: bold;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .home-h1-div-intro {
      max-width: 50rem;
      font-size: 100px;
      font-family: Gravity-Light;
      font-weight: bold;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .home-h1-div-intro {
      max-width: 50rem;
      font-size: 100px;
      font-family: Gravity-Light;
      font-weight: bold;
    }
  }
}
