.content {
  /* width: 70vw;
  height: 20vw;
  position: absolute;
  z-index: -1;
  transform: translate(350px, -20px);
  filter: blur(10px);
  max-width: 50rem;
  border: 1px solid red; */
  position: absolute;
  width: 600px;
  height: 500px;
  will-change: filter;
  filter: blur(15px);
  z-index: -1;
  transform: translate(450px, 30px);
  max-width: fit-content;
  max-height: fit-content;
}

.content svg {
  /* filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4)); */
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 300px) {
    .content {
      transform: translate(40px, 120px);
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .content {
      transform: translate(100px, 150px);
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 601px) {
    .content {
      transform: translate(140px, 150px);
    }
  }

  @media only screen and (min-width: 602px) {
    .content {
      transform: translate(140px, 150px);
    }
  }

  @media only screen and (min-width: 620px) {
    .content {
      transform: translate(120px, 150px);
    }
  }

  @media only screen and (min-width: 680px) {
    .content {
      transform: translate(130px, 150px);
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 769px) {
    .content {
      transform: translate(300px, 150px);
    }
  }

  @media only screen and (min-width: 820px) {
    .content {
      transform: translate(380px, 110px);
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .content {
      transform: translate(400px, 110px);
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .content {
      transform: translate(450px, 110px);
    }
  }
}
