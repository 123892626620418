@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

:root {
  --main-text-color: rgb(26 65 119);
  --main-button-color: #0582c8;
  --font-size-text: 0.9rem;
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
}

.carousel-form-outer-div {
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 10rem;
}

.carousel-form-inner-div {
  max-width: 35rem;
  margin: 0 auto;
  font-family: Gravity;
  color: var(--var-med-blue);
  background-color: white;
  border-radius: 12px;
  padding: 3rem 3rem 3rem 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.carousel-form-title {
  font-size: 2.5rem;
  /* padding-top: 2rem; */
}

.carousel-form-text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: var(--var-med-blue);
  font-style: italic;
}

.carousel-form-button,
.carousel-form-button-disable {
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: var(--main-button-color);
  color: white;
  border-radius: 16px;
  cursor: pointer;
  font-family: Gravity;
  font-size: var(--font-size-text);
  margin-top: 1rem;
}

.carousel-form-button-disable {
  background-color: lightgray;
}

.carousel-form-button:hover {
  background-color: #046aa6;
}

.carousel-form-upload-text-outer {
  max-width: fit-content;
  margin: 0 auto;
}

.carousel-form-upload-text {
  /* margin-left: 1rem;
  margin-right: 1rem; */
  margin-top: 1rem;
  font-size: 0.8rem;
}

.carousel-download-a {
  text-decoration: none;
  color: white;
}
