@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts/gravity/Gravity-Regular.otf");
}

@font-face {
  font-family: Auger-Mono-Bold;
  src: url("../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-regular.woff2");
}

:root {
  --main-text-color: rgb(26 65 119);
}

.symposia-outside-div {
  background-color: var(--main-text-color);
}
.symposia-main-div {
  max-width: 45rem;
  margin: 0 auto;
  font-family: Gravity;
  margin-top: 5rem;
  margin-bottom: 0rem;
}

.symposia-max-header {
  font-size: 2.5rem;
  padding-top: 10rem;
  margin-left: 0.5rem;
  color: white;
}

.symposia-main-text {
  color: white;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.symposia-main-div-under-text {
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 3rem;
  padding-bottom: 1rem;
}

.symposia-form-section {
  max-width: fit-content;
  margin: 0 auto;
  padding-bottom: 10rem;
}

/* * {
  border: 1px solid red;
} */
