@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts/gravity/Gravity-Regular.otf");
}

@font-face {
  font-family: Auger-Mono-Bold;
  src: url("../../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-regular.woff2");
}

:root {
  --symposia-text-color: #0283c9;
  --font-size: 0.875rem;
}

.conference-program-drop-down-div {
  max-width: 95vw;
  margin: 0 auto;
}

.conference-program-drop-down-div-faq-line-middle {
  color: #74a441;
}

.conference-program-drop-down-div-line-rotate {
  color: #74a441;
  transition: 0.5s;
}

.conference-program-drop-down-div-line-rotate-active {
  color: #74a441;
  transform-origin: center;
  transform: rotate(-90deg);
  transition: 0.5s;
}

.conference-program-drop-down-div-active-content {
  overflow: hidden;
  transition: 0.5s;
  color: white;
  font-size: var(--font-size);
  font-family: Gravity;
}

.conference-program-drop-down-div-title {
  font-family: Auger-Mono-Bold;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size);
  transition: 0.5s;
  color: var(--symposia-text-color);
  cursor: pointer;
}

.conference-program-drop-down-div-title-active {
  margin-top: 1rem;
  font-family: Auger-Mono-Bold;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size);
  transition: 0.5s;
  color: var(--symposia-text-color);
  cursor: pointer;
}
