@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts/gravity/Gravity-Regular.otf");
}

:root {
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
}
.sponsorships-div-section {
  max-width: 55rem;
  margin: 0 auto;
  font-family: Gravity;
  color: rgb(26 65 119);
  margin-top: 10rem;
  opacity: 100;
}

.sponsorships-main-header {
  font-size: 2.5rem;
  margin-left: 1rem;
}

.sponsorships-main-flex {
  margin-top: 5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.sponsorships-main-coming-soon-text {
  color: var(--var-med-blue);
}
