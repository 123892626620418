@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

:root {
  --main-text-color: rgb(26 65 119);
  --secondary-text-color: #0582c9;
  --main-font-size: 0.9rem;
  --main-large-font-size: 1.1rem;
  --main-small-font-size: 0.7rem;
  --main-font-weight: 500;
}

.symposia-form-main-text {
  font-size: 2.5rem;
  padding-top: 2rem;
}

.symposia-form-main-div {
  max-width: 35rem;
  margin: 0 auto;
  color: var(--main-text-color);
  background-color: white;
  border-radius: 12px;
  padding: 3rem 3rem 3rem 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.symposia-form-label label,
.symposia-form-label-organizer label,
.symposia-form-label-abstract label,
.symposia-form-label-summary label,
.symposia-form-label-draft label,
.symposia-form-label-speakers label {
  color: var(--secondary-text-color);
  font-family: Gravity;
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  text-transform: capitalize;
}

.symposia-form-label-organizer,
.symposia-form-label-draft,
.symposia-form-label-abstract,
.symposa-form-grid-young-investigator,
.symposia-form-label-summary,
.symposia-form-label-speakers {
  margin-top: 1rem;
}

.symposia-form-label input:required:valid,
.symposia-form-label-organizer input:required:valid,
.symposia-form-label-abstract textarea:required:valid,
.symposia-form-label-summary input:required:valid,
.symposia-form-label-draft input:required:valid,
.symposia-form-label-speakers input:required:valid {
  border: 1px solid lightgray;
}

.symposia-form-label input:focus:invalid,
.symposia-form-label-organizer input:focus:invalid,
.symposia-form-label-abstract textarea:focus:invalid,
.symposia-form-label-summary input:focus:invalid,
.symposia-form-label-draft input:focus:invalid,
.symposia-form-label-speakers input:focus:invalid {
  border: 1px solid red;
}

.symposia-form-text-young-investigator {
  color: var(--secondary-text-color);
  font-family: Gravity;
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
}

.symposia-form-text-young-investigator-restriction {
  color: gray;
  font-family: Gravity;
  font-size: var(--main-small-font-size);
  font-weight: var(--main-font-weight);
  max-width: 20rem;
}

.symposia-form-button,
.symposia-form-button-active {
  border: 1px solid lightgray;
  color: var(--secondary-text-color);
  background: white;
  border-radius: 16px;
  font-family: Gravity;
  font-size: var(--main-font-size);
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  cursor: pointer;
  transition: 0.1s;
}

.symposia-form-button:hover {
  color: white;
  background: var(--secondary-text-color);
  transition: 0.1s;
}

.symposia-form-button-active {
  color: white;
  background: var(--secondary-text-color);
  transition: 0.1s;
}

.symposia-form-label-organizer {
  margin-top: 1rem;
}

.symposia-form-side-text {
  font-family: Gravity;
  font-size: var(--main-font-size);
  color: var(--secondary-text-color);
  margin-top: 1rem;
}

.symposia-form-grid-alternate {
  margin-top: 1rem;
}

.symposia-form-submit-button {
  margin-top: 2rem;
  font-family: Gravity;
  font-size: 0.8rem;
  border-radius: 16px;
  padding-left: 4rem;
  padding-right: 4rem;
}

.symposia-form-less-than-660-div {
  max-width: fit-content;
  margin: 0 auto;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    :root {
      --main-font-size: 0.7rem;
    }
  }

  @media only screen and (min-width: 400px) {
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 501px) {
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 660px) {
    :root {
      --main-font-size: 0.9rem;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 890px) {
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
