@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

@font-face {
  font-family: GravityBold;
  src: url("../../public/Fonts/gravity/Gravity-Bold.otf");
}

:root {
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
}

.pricing-nrs-main-div {
  max-width: fit-content;
  margin: 0 auto;
  color: var(--var-dark-blue);
  margin-top: 15rem;
  margin-bottom: 10rem;
}

.pricing-nrs-main-text {
  font-size: 2.5rem;
  padding-top: 2rem;
}

.pricing-nrs-register-button {
  background-color: gray;
  border-radius: 20px;
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  max-width: 15rem;
  color: var(--var-white);
}

.pricing-nrs-register-button:hover {
  background-color: gray;
  cursor: pointer;
}

.pricing-nrs-registration-info {
  max-width: 25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.pricing-nrs-vertical-line-730 {
  border-bottom: 1px solid lightgray;
  width: 350px;
  /* margin-left: 3rem; */
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.pricing-nrs-vertical-line {
  height: 280px;
  border-right: 1px solid lightgray;
  margin-right: 4rem;
}
