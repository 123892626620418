@font-face {
  font-family: Auger-Mono;
  src: url("../../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-light.woff2");
}

@font-face {
  font-family: Auger-Mono-Bold;
  src: url("../../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-regular.woff2");
}

@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

:root {
  --symposia-text-color: #87ccf2;
  --symposia-text-color-second: #36b8ff;
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --font-size: 0.875rem;
}

.symposia-drop-down-div {
  max-width: 95vw;
  margin: 0 auto;
}

.symposia-drop-down-div-title {
  font-family: Auger-Mono-Bold;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size);
  transition: 0.5s;
  color: var(--var-light-blue);
  cursor: pointer;
}

.symposia-drop-down-div-title-active {
  margin-top: 1rem;
  font-family: Auger-Mono-Bold;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size);
  transition: 0.5s;
  color: var(--var-light-blue);
  cursor: pointer;
}

.symposia-drop-down-div-active-content {
  overflow: hidden;
  transition: 0.5s;
  color: white;
  font-size: var(--font-size);
  font-family: Gravity;
}

.symposia-drop-down-div-svg {
  transition: 0.5s;
  cursor: pointer;
}

.symposia-drop-down-div-svg-active {
  margin-top: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.symposia-drop-down-div-faq-line-middle {
  color: #74a441;
}

.symposia-drop-down-div-line-rotate {
  color: #74a441;
  transition: 0.5s;
}

.symposia-drop-down-div-line-rotate-active {
  color: #74a441;
  transform-origin: center;
  transform: rotate(-90deg);
  transition: 0.5s;
}
