@font-face {
  font-family: Auger-Mono-Bold;
  src: url("../../../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-regular.woff2");
}

@font-face {
  font-family: Gravity;
  src: url("../../../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

.attendee-contact-info-main-flex {
  max-width: fit-content;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  border: 0.5px solid #0582c8;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 12px;
}

.attendee-contact-info-main-text {
  font-size: 1.5rem;
  font-family: Gravity;
  text-transform: uppercase;
  color: #0582c8;
}

.attendee-contact-info-inputs label {
  font-family: Gravity;
  color: #1a4177;
}

.attendee-contact-information-profession-section {
  margin-top: 1rem;
}

.attendee-contact-information-email-section {
  margin-top: 1rem;
}

.attendee-contact-info-italic {
  font-style: italic;
  font-size: 0.8rem;
  font-family: Gravity;
  color: #1a4177;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 300px) {
    .attendee-contact-info-inputs label {
      font-family: Gravity;
      color: #1a4177;
      font-size: 0.7rem;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 930px) {
    .attendee-contact-info-inputs label {
      font-family: Gravity;
      color: #1a4177;
      font-size: 0.875rem;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
