@font-face {
  font-family: Auger-Mono;
  src: url("../../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-light.woff2");
}

@font-face {
  font-family: Auger-Mono-Bold;
  src: url("../../../public/Fonts-Two/Auger\ Mono\ Webfont/augermono-regular.woff2");
}

@font-face {
  font-family: Gravity;
  src: url("../../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

:root {
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --gap: 1rem;
  --font-size: 0.875rem;
}

.drop-down-faq-div-active-content {
  overflow: hidden;
  transition: 0.5s;
}
.drop-down-faq-div {
  max-width: 98vw;
  margin: 0 auto;
}

.drop-down-faq-title {
  /* margin-top: 1rem; */
  font-family: Auger-Mono-Bold;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size);
  transition: 0.5s;
  cursor: pointer;
}

.drop-down-faq-title-active {
  margin-top: 1rem;
  font-family: Auger-Mono-Bold;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size);
  transition: 0.5s;
  cursor: pointer;
}

.drop-down-faq-paragraph {
  font-family: Gravity;
  font-size: var(--font-size);
  max-width: 90%;
  margin-top: 1rem;
  transition: 0.5s;
  color: var(--var-dark-blue);
}

.drop-down-faq-paragraph-active {
  font-family: Gravity;
  font-size: var(--font-size);
  max-width: 90%;
  margin-top: 1rem;
  /* margin-top: 1rem; */
  transition: 0.5s;
  color: var(--var-dark-blue);
}

.drop-down-faq-links {
  color: #81c0e3;
  font-family: Gravity;
  font-size: var(--font-size);
  transition: 0.5s;
  margin-top: 1.5rem;
}

.drop-down-faq-links-active {
  color: #0082c8;
  font-family: Gravity;
  font-size: var(--font-size);
  margin-top: 1.5rem;
  /* margin-top: 2rem; */
  transition: 0.5s;
  text-decoration: underline;
  text-decoration-color: transparent;
}

.drop-down-faq-links a {
  color: #0082c8;
  text-decoration: none;
  max-width: fit-content;
}

.drop-down-faq-links-active a {
  color: #0082c8;
  text-decoration: none;
  max-width: fit-content;
}

.drop-down-faq-links-active:hover {
  text-decoration: underline;
  text-decoration-color: #0191de;
}

.drop-down-faq-line-rotate {
  color: #74a441;
  /* rotate: 90deg; */
  /* transform: rotate(90deg); */
  transition: 0.5s;
}

.drop-down-faq-line-middle {
  color: #74a441;
}

.drop-down-faq-line-rotate-active {
  color: #74a441;
  /* rotate: 90deg; */
  transform-origin: center;
  transform: rotate(-90deg);
  transition: 0.5s;
}

.drop-down-svg-div {
  transition: 0.5s;
  cursor: pointer;
}
.drop-down-svg-div-active {
  margin-top: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.drop-down-faq-links:last-child {
  margin-bottom: 0.1rem;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .drop-down-faq-title {
      font-family: Auger-Mono-Bold;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size);
      transition: 0.5s;
      cursor: pointer;
    }

    .drop-down-faq-title-active {
      margin-top: 1rem;
      font-family: Auger-Mono-Bold;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size);
      transition: 0.5s;
      cursor: pointer;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .drop-down-faq-title {
      font-family: Auger-Mono-Bold;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size);
      transition: 0.5s;
      cursor: pointer;
    }

    .drop-down-faq-title-active {
      margin-top: 1rem;
      font-family: Auger-Mono-Bold;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size);
      transition: 0.5s;
      cursor: pointer;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .drop-down-faq-title {
      font-family: Auger-Mono-Bold;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size);
      transition: 0.5s;
      cursor: pointer;
    }

    .drop-down-faq-title-active {
      margin-top: 1rem;
      font-family: Auger-Mono-Bold;
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size);
      transition: 0.5s;
      cursor: pointer;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
