@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

.faq-main-div {
  max-width: 55rem;
  margin: 0 auto;
  font-family: Gravity;
  color: rgb(26 65 119);
  margin-top: 10rem;
  opacity: 0;
}

.faq-main-div-active {
  max-width: 55rem;
  margin: 0 auto;
  font-family: Gravity;
  color: rgb(26 65 119);
  margin-top: 10rem;
  opacity: 1;
  transition: 0.8s ease;
}

.faq-main-header {
  font-size: 2.5rem;
}

.faq-rogers-arena-picture {
  color: rgb(26 65 119);
  margin-top: 10rem;
  margin-bottom: 15rem;
  opacity: 0;
  transform: translateY(100px);
}

.faq-rogers-arena-picture-active {
  margin-top: 10rem;
  margin-bottom: 15rem;
  opacity: 1;
  transform: translateY(0px);
  transition: 0.5s;
}

.faq-roger-roll-over {
  color: transparent;
  position: absolute;
  transform: translate(5px, -25px);
  transition: 0.5s;
}

.faq-roger-roll-over-active {
  color: white;
  position: absolute;
  transform: translate(5px, -25px);
  transition: 0.5s;
}

.faq-parallax-rogers {
  height: 600px;
  width: 50%;
  background-image: url("../Images/nicolas-savignat-UxK-LXQraJ4-unsplash.jpg");
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: cover;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 300px) {
    .faq-main-div-active,
    .faq-main-div {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    .faq-main-header {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .faq-main-div-active,
    .faq-main-div {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-top: 10rem;
    }

    .faq-main-header {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .faq-main-div-active,
    .faq-main-div {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-top: 10rem;
    }

    .faq-main-header {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .faq-main-div-active,
    .faq-main-div {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 10rem;
    }

    .faq-main-header {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  @media only screen and (min-width: 820px) {
    .faq-main-div-active,
    .faq-main-div {
      margin: 0 auto;
      margin-top: 10rem;
    }

    .faq-main-header {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .faq-main-header {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
