:root {
  --light-grey: #f6f9fc;
  --dark-terminal-color: #0a2540;
  --accent-color: #635bff;
  --radius: 3px;
}

#payment-form {
  border: #f6f9fc solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
    0 30px 60px -30px rgb(0 0 0 / 30%);
  max-width: 60rem;
  margin: 0 auto;
}

.payment-submit {
  background-color: var(--accent-color);
}

.payment-submit {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
.payment-submit:hover {
  filter: contrast(115%);
}
.payment-submit:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
.payment-submit:disabled {
  opacity: 0.5;
  cursor: none;
}

.payment-div-container {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.payment-completion {
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#payment-message {
  text-transform: uppercase;
  margin-top: 1rem;
  font-style: italic;
}
