@font-face {
  font-family: Gravity-Bold;
  src: url("../../../public/Fonts/gravity/Gravity-Bold.otf");
}

.nav-bar-picture {
  margin-left: 1rem;
  margin-top: 1rem;
}
.nav-bar-whole {
  margin-right: 1rem;
  margin-left: 15rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.nav-bar-different-sections {
  color: rgb(26 65 119);
  cursor: pointer;
  font-family: Gravity-Bold;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.5s;
}

.nav-bar-different-sections:hover,
.nav-bar-different-sections-burger:hover {
  text-decoration-color: rgb(26 65 119);
  text-decoration: underline;
}

.nav-bar-different-sections-burger {
  color: rgb(26 65 119);
  cursor: pointer;
  font-family: Gravity-Bold;
  text-transform: uppercase;
  font-size: 25px;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.5s;
}

.nav-bar-tri-text {
  text-align: left;
  color: white;
  font-weight: bolder;
  cursor: pointer;
}

.nav-bar-container {
  max-width: fit-content;
  margin-top: 0.5rem;
  background-color: white;
  z-index: 11;
}

.drop-burger-line {
  transition: 0.5s;
  opacity: 1;
}

.drop-down-burger-line-rotate-bottom-active {
  /* y: 45;
  transform-origin: center;
  transform: rotate(-45deg); */
  opacity: 0;
  transition: 0.1s;
}

.drop-down-burger-line-rotate-top-active {
  /* y: 45;
  transform-origin: center;
  transform: rotate(-45deg); */
  opacity: 0;
  transition: 0.1s;
}

.drop-down-burger-line-rotate-middle-active {
  transform-origin: center;
  transform: rotate(45deg);
  transition: 0.5s;
}

.drop-down-burger-line-rotate-middle-two-active {
  transform-origin: center;
  transform: rotate(-45deg);
  transition: 0.5s;
}

.nav-bar-for-access {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  display: block;
  top: 0px;
  z-index: 10;
  /* border-bottom: 0.1px solid black; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  transition: 0.5s;
}

.nav-bar-for-access-active {
  background-color: white;
  position: sticky;
  position: -webkit-sticky;
  display: block;
  top: 0px;
  z-index: 10;
  /* border-bottom: 0.1px solid black; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.5s;
}

.nav-bar-hamburger-div {
  margin-left: 50vw;
}

.nav-bardrop-down-burger-div-active-content {
  overflow: hidden;
  transition: 0.5s;
  position: absolute;
  background-color: white;
  opacity: 0.5s;
  border-radius: 12px;
}

.nav-bar-burger-version {
  padding-top: 0.5rem;
  padding-right: 40vw;
  padding-left: 10vw;
  /* flex: 1; */
}

.nav-bar-burger-overlay {
  min-height: 100vh;
  /* width: 0; */
  position: fixed;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: block;
  z-index: 1;
  /* display: flex;
  flex-flow: column;
  justify-content: center; */
  top: 0;
  bottom: 0;
  /* display: block; */
}

.nav-bar-symposia,
.nav-bar-posters {
  /* display: block; */
  /* top: 55px; */
  /* margin-top: 0.5rem; */
  color: rgb(26 65 119);
  cursor: pointer;
  font-family: Gravity-Bold;
  text-transform: uppercase;
  font-size: 10px;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.5s;
  max-width: 5rem;
  -webkit-animation: fadeIn 0.2s;
  animation: fadeIn 0.2s;
}

.nav-bar-symposia-and-posters {
  position: absolute;
  top: 40px;
  background-color: white;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav-bar-posters {
  margin-top: 1rem;
}

.nav-bar-posters:hover,
.nav-bar-symposia:hover {
  text-decoration-color: rgb(26 65 119);
  text-decoration: underline;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .nav-bar-hamburger-div {
      margin-left: 15vw;
    }
    .nav-bar-picture {
      margin-right: 5vw;
    }

    .nav-bar-picture {
      margin-top: 0.5rem;
    }
  }

  @media only screen and (min-width: 400px) {
    .nav-bar-hamburger-div {
      margin-left: 25vw;
    }
    .nav-bar-picture {
      margin-right: 5vw;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 501px) {
    .nav-bar-hamburger-div {
      margin-left: 40vw;
    }
    .nav-bar-picture {
      margin-right: 5vw;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .nav-bar-hamburger-div {
      margin-left: 45vw;
    }
    .nav-bar-picture {
      margin-right: 5vw;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .nav-bar-hamburger-div {
      margin-left: 50vw;
    }
    .nav-bar-picture {
      margin-right: 10vw;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 890px) {
    .nav-bar-hamburger-div {
      margin-left: 50vw;
    }
    .nav-bar-picture {
      margin-right: 0vw;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1186px) {
    .nav-bar-picture {
      margin-top: 1rem;
    }

    .nav-bar-whole {
      margin-right: 1rem;
      margin-left: 5rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  @media only screen and (min-width: 1300px) {
    .nav-bar-whole {
      margin-right: 1rem;
      margin-left: 15rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}
