@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

.venue-i-frame-div,
.venue-i-frame-div-flex {
  max-width: 60rem;
  margin: 0 auto;
  margin-top: 10rem;
  color: rgb(26 65 119);
  font-family: Gravity;
}

.venue-title-event-location,
.venue-title-event-location-flex {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.venue-text-div-right {
  margin-top: 10%;
  opacity: 0;
  margin-left: 2rem;
}

.venue-text-div-right-active {
  margin-top: 10%;
  opacity: 1;
  transition: 3s ease;
  margin-left: 2rem;
}

.venue-text-location {
  font-size: 0.9rem;
}

.venue-text-website {
  color: #0082c8;
  font-size: 0.9rem;
}

.venue-text-title,
.venue-text-title-flex {
  font-size: 1.8rem;
}

.venue-text-title-flex {
  margin-top: 1rem;
}
.venue-text-website a {
  color: #0082c8;
  text-decoration: none;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.5s;
}

.venue-text-website-flex a {
  color: #0082c8;
  text-decoration: none;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.5s;
}

.venue-text-website a:hover,
.venue-text-website-flex a:hover {
  text-decoration-color: #0191de;
}

.venue-iframe-div {
  margin-left: 1rem;
}

.venue-iframe-div {
  opacity: 0;
}

.venue-iframe-div-active {
  opacity: 1;
  transition: 0.8s ease;
}

.venue-iframe-div-flex-active {
  /* border: 1px solid red; */
  max-width: fit-content;
  margin: 0 auto;
}
/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 300px) {
    .venue-text-title-flex {
      font-size: 1.5rem;
    }
    .venue-text-location-flex {
      font-size: 0.9rem;
    }
    .venue-text-website-flex {
      font-size: 0.9rem;
    }
    .venue-title-event-location-flex {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      margin-left: 0rem;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 500px) {
    .venue-text-title-flex {
      font-size: 1.5rem;
    }
    .venue-text-location-flex {
      font-size: 0.9rem;
    }
    .venue-text-website-flex {
      font-size: 0.9rem;
    }
    .venue-title-event-location-flex {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      margin-left: 0rem;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .venue-text-title-flex {
      font-size: 1.6rem;
    }
    .venue-text-location-flex {
      font-size: 0.9rem;
    }
    .venue-text-website-flex {
      font-size: 0.9rem;
    }
    .venue-title-event-location-flex {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      margin-left: 0rem;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .venue-text-title-flex {
      font-size: 1.6rem;
    }
    .venue-text-location-flex {
      font-size: 0.9rem;
    }
    .venue-text-website-flex {
      font-size: 0.9rem;
    }
    .venue-title-event-location-flex {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      margin-left: 0rem;
    }
  }
  @media only screen and (min-width: 820px) {
    .venue-text-title-flex {
      font-size: 1.6rem;
    }
    .venue-text-location-flex {
      font-size: 0.9rem;
    }
    .venue-text-website-flex {
      font-size: 0.9rem;
    }

    .venue-title-event-location-flex {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      margin-left: 0rem;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 982px) {
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
