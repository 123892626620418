@font-face {
  font-family: Gravity;
  src: url("../../../../public/Fonts/gravity/Gravity-Regular.otf");
}

:root {
  --main-text-color: rgb(26 65 119);
  --font-size: 0.875rem;
}
.general-information-main-div {
  margin-top: 1rem;
  font-size: var(--font-size);
  margin-bottom: 1rem;
  font-family: Gravity;
  color: white;
  word-break: break-word;
  max-width: fit-content;
  text-transform: none;
  text-decoration: none;
  /* max-width: 20rem; */
}
