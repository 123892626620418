@font-face {
  font-family: Gravity;
  src: url("../../public/Fonts-Two/Gravity/Gravity-Regular.otf");
}

:root {
  --location-color: #86ccf2;
  --happen-color: rgb(26 65 119);
  --break-color: gray;
  --var-white: #ffffff;
  --var-black: #000000;
  --var-dark-blue: #194177;
  --var-med-blue: #0482c8;
  --var-light-blue: #86ccf2;
  --var-green: #73a441;
  --font-size: 0.875rem;
}

.registration-form-nrs-main-div {
  max-width: fit-content;
  margin: 0 auto;
  color: var(--var-dark-blue);
}

.registration-form-nrs-main-text {
  font-size: 2.5rem;
  padding-bottom: 0.5rem;
  font-family: Gravity;
  margin-left: 1rem;
  margin-right: 1rem;
}

.registration-form-nrs-button-active {
  background-color: var(--var-med-blue);
  color: var(--var-white);
  border-radius: 16px;
  border: none;
  max-height: 3rem;
  /* padding-left: 1rem;
  padding-right: 1rem; */
}

.registration-form-nrs-button {
  background-color: white;
  border-radius: 16px;
  border: 1px solid lightgray;
  color: var(--var-dark-blue);
  max-height: 3rem;
  /* padding-left: 1rem;
  padding-right: 1rem; */
}

.registration-form-nrs-flex-2,
.registration-form-nrs-flex-1,
.registration-form-nrs-flex-0 {
  color: var(--var-med-blue);
}

.registration-form-nrs-flex-3 {
  color: black;
  max-width: 40rem;
  font-size: var(--font-size);
  word-break: normal;
  overflow-y: scroll;
  max-height: 15rem;
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1rem 1rem 1rem 1rem;
}

.registration-form-select {
  width: 100%;
  min-height: 2.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border: 0.0625rem solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  resize: none;
  background-color: white;
}

.registration-label-color label {
  color: var(--var-med-blue);
  font-weight: normal;
}

/*Max-width is basically saying 500px, if youre in that this will happen*/
@media all {
  /* xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px */

  @media only screen and (min-width: 200px) {
    .registration-form-nrs-button-active {
      background-color: var(--var-med-blue);
      color: var(--var-white);
      border-radius: 16px;
      border: none;
      max-height: 3rem;
      font-size: 0.6rem;
      /* padding-left: 1rem;
      padding-right: 1rem; */
    }

    .registration-form-nrs-button {
      background-color: white;
      border-radius: 16px;
      border: 1px solid lightgray;
      color: var(--var-dark-blue);
      max-height: 3rem;
      font-size: 0.6rem;
      /* padding-left: 1rem;
      padding-right: 1rem; */
    }

    .registration-form-nrs-dinner-text {
      max-width: 15rem;
      font-size: 0.6rem;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: 600px) {
    .registration-form-nrs-button-active {
      background-color: var(--var-med-blue);
      color: var(--var-white);
      border-radius: 16px;
      border: none;
      max-height: 3rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: var(--font-size);
    }

    .registration-form-nrs-button {
      background-color: white;
      border-radius: 16px;
      border: 1px solid lightgray;
      color: var(--var-dark-blue);
      max-height: 3rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: var(--font-size);
    }

    .registration-form-nrs-dinner-text {
      max-width: 30rem;
      font-size: var(--font-size);
    }
  }
}
